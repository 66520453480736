import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const StaggeredBlocks = ({ titleField, data, taller }) => {
  const { t } = useTranslation();

  // Start: Stagger
  const [isVisible, setIsVisible] = useState(false);
  const { ref: wrapperRef, inView: wrapperInView } = useInView({
    triggerOnce: true,
  });

  const animationRefs = useRef([]);

  useEffect(() => {
    if (wrapperInView) {
      setIsVisible(true);
    }
  }, [wrapperInView]);
  // End: Stagger

  const cardVariants = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 2,
        delay: 0.2,
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative z-30" style={{ height: "auto" }}>
      <motion.div
        className="max-w-screen-lg justify-center items-center flex flex-col"
        initial="offscreen"
        whileInView="onscreen"
        variants={cardVariants}
        viewport={{ once: true }}
      >
        <h2 className="text-4xl md:text-5xl px-4 py-6 font-geologica font-light leading-tight tracking-tight uppercase text-center relative text-white">
          {t(titleField)}
        </h2>
      </motion.div>

      <div ref={wrapperRef} className="max-w-screen-xl w-full flex items-start justify-center flex-wrap">
        {data.map((xp, index) => (
          <div key={index}>
            <motion.div
              ref={(element) => (animationRefs.current[index] = element)}
              initial={{ opacity: 0, y: -100 }}
              animate={isVisible ? { opacity: 1, y: 0 } : {}}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: index * 0.1 }} // Add stagger delay
              whileHover={{ scale: 1.08 }}
              style={{ originY: 0.5 }}
              className="xpBlocks"
            >
              <div
                className={`flex justify-center items-center m-6 p-4 shadow-[0_10px_10px_-3px_rgba(0,0,0,0.12),0_10px_20px_-2px_rgba(0,0,0,0.04)] w-[300px] ${
                  taller ? "h-[240px]" : "h-[180px]"
                }`}
                style={{ background: "linear-gradient(rgb(20 74 81), rgb(6 71 78))" }}
              >
                <p className="text-xl uppercase text-center text-white font-avertaCyrillic">{xp.title}</p>
              </div>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaggeredBlocks;
