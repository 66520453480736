import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CustomHeader from "../components/elements/CustomHeader";
import OurStats from "../components/OurStats";
import BannerHeader from "../components/elements/BannerHeader";
import StaggeredBlocks from "../components/StaggeredBlocks";
import JoinUsSection from "../components/JoinUsSection";
import ServiceBlock from "../components/elements/ServiceBlock";

const ServicesLandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const [refStats, statsInView] = useInView({ triggerOnce: true, threshold: 0.2 });

  const sectionsData = [
    {
      imageSrc: "/images/group2.jpg",
      title: t("services1Title"),
      url: "consulting",
    },
    {
      imageSrc: "/images/group3.jpg",
      title: t("services2Title"),
      url: "advocacy",
    },
    {
      imageSrc: "/images/group5.jpg",
      title: t("services3Title"),
      url: "research",
    },
    {
      imageSrc: "/images/group6.jpg",
      title: t("services4Title"),
      url: "management",
    },
  ];

  const sectionsExperiences = [
    {
      title: t("xpTitle1"),
    },
    {
      title: t("xpTitle2"),
    },
    {
      title: t("xpTitle3"),
    },
    {
      title: t("xpTitle4"),
    },
    {
      title: t("xpTitle5"),
    },
    {
      title: t("xpTitle6"),
    },
    {
      title: t("xpTitle7"),
    },
    {
      title: t("xpTitle8"),
    },
    {
      title: t("xpTitle9"),
    },
    {
      title: t("xpTitle10"),
    },
    {
      title: t("xpTitle11"),
    },
    {
      title: t("xpTitle12"),
    },
    {
      title: t("xpTitle13"),
    },
    {
      title: t("xpTitle14"),
    },
    {
      title: t("xpTitle15"),
    },
    {
      title: t("xpTitle16"),
    },
    {
      title: t("xpTitle17"),
    },
    {
      title: t("xpTitle18"),
    },
    {
      title: t("xpTitle19"),
    },
    {
      title: t("xpTitle20"),
    },
    {
      title: t("xpTitle21"),
    },
  ];

  return (
    <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5 }}>
      <NavBar />

      <BannerHeader headerText={t("pageServices")} bgImage={"columns.jpg"} bottomSpace={true} />

      {/*
        ||
        || 🟩 Services
        ||
        */}
      <div className="bg-white w-full h-full flex flex-col justify-center items-center min-h-svh overflow-x-hidden" style={{ height: "auto" }}>
        {sectionsData.map((section, index) => (
          <div className="w-full mb-32 mt-[100px]" key={index}>
            <div className="block bg-white">
              <ServiceBlock data={section} indexBlock={index} />
            </div>
          </div>
        ))}
      </div>

      <div className="mt-16">
        <CustomHeader isPrimary={false} headerText={t("ourExperience")} />
      </div>

      <StaggeredBlocks titleField={t("experience")} data={sectionsExperiences} taller={false} />

      <JoinUsSection />

      {/*
        ||
        || 🟩 Stats
        ||
        */}
      <div className="gradientMasterSet w-full flex flex-col justify-center items-center" ref={refStats}>
        <div className="p-12">
          <CustomHeader headerText={t("ourStatTitle")} />

          <motion.div key="OurStats" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <OurStats isVisible={statsInView} />
          </motion.div>
        </div>
      </div>

      <Footer showContactButton={true} />
    </motion.div>
  );
};

export default ServicesLandingPage;
