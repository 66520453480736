import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const JoinUsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="py-24 my-24 bg-cover bg-center relative flex items-center justify-center">
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <div className="w-full h-full" style={{ backgroundImage: "url(/images/ub2.jpg)", filter: "blur(3px) drop-shadow(0 0 0 transparent)" }}></div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full opacity-70 bg-master-400"></div>

      <div className="polyStaticOuterTL bg-master-200 bg-opacity-40"></div>
      <div className="polyStaticOuterTR bg-master-200 bg-opacity-40"></div>
      <div className="polyStaticOuterBL bg-master-100 bg-opacity-30"></div>

      <div className="relative z-10 flex flex-col items-center justify-center">
        <div className="max-w-[480px] mx-12 mb-12 text-center">
          <span className="text-white text-3xl font-geologica font-bold uppercase mr-4">{t("joinUsSlogan")}</span>
        </div>

        <Link
          to="/join"
          className="inline-block border-4 border-white px-6 py-3 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:text-white focus:border-white focus:text-white focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic"
          data-twe-ripple-init
        >
          {t("joinUsTitle")}
        </Link>
      </div>
    </div>
  );
};

export default JoinUsSection;
