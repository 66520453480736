import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const navStyles = {
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  left: "0",
};

const NavBar = () => {
  const { t, i18n } = useTranslation();

  const [activeSubMenu, setActiveSubMenu] = useState(null);

  //
  // Navigation Dom, Active class
  //
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    // Extract the pathname from the current URL
    const currentPath = location.pathname;

    // Set the active link based on the current pathname
    setActiveLink(currentPath);
  }, [location.pathname]);

  const [isToggled, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!isToggled);
  };

  const navContainer = {
    visible: {
      opacity: 1,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
  };

  /** add this const **/
  const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: { opacity: 0, y: -25 },
  };

  const menuItems = [
    { id: 1, label: t("pageAbout"), path: "/about" },
    {
      id: 2,
      label: t("pageServices"),
      path: "/services",
      subMenus: [
        {
          id: 1,
          label: t("services1Title"),
          path: "/services/consulting",
        },
        {
          id: 2,
          label: t("services2Title"),
          path: "/services/advocacy",
        },
        {
          id: 3,
          label: t("services3Title"),
          path: "/services/research",
        },
        {
          id: 4,
          label: t("services4Title"),
          path: "/services/management",
        },
      ],
    },
    { id: 3, label: t("pageTeam"), path: "/team" },
    { id: 4, label: t("pageJoin"), path: "/join" },
    { id: 5, label: t("pageContact"), path: "/contact" },
  ];

  // ======================================================================== //
  // 🟨 Functions

  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowButton(window.innerWidth <= 1024);
      // setToggle(false);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showSubMenu, setShowSubMenu] = useState(false);

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  //
  //
  // Language
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Initialize with 'en' as the default language
  const [showLanguages, setShowLanguages] = useState(false);
  const languages = [
    { code: "mn", flag: "🇲🇳" },
    { code: "en", flag: "🇬🇧" },
    { code: "cn", flag: "🇨🇳" },
    { code: "tr", flag: "🇹🇷" },
  ];
  const buttonRef = useRef(null);

  // Get language from the URL
  useEffect(() => {
    const languageFromUrl = window.location.pathname.split("/")[1];

    if (languageFromUrl === "en") {
      setCurrentLanguage("mn");
      i18n.changeLanguage("mn");
    }
  }, [currentLanguage, i18n]);

  // show language picker
  const handleButtonClick = () => {
    setShowLanguages(!showLanguages);
  };

  // click outside close languages
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowLanguages(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const changeLanguage = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    setShowLanguages(false);
    i18n.changeLanguage(newLanguage);

    // const languageFromUrl = window.location.pathname.split("/")[1];
    // if (languageFromUrl === "en") {
    //   window.history.pushState({}, "", "/");
    // }

    // if (languageFromUrl === "") {
    //   window.history.pushState({}, "", "/en");
    // }
  };
  // Language
  //
  //

  return (
    <div className={`z-50 relative ${!isToggled ? "" : "h-svh w-full"}`}>
      <motion.nav /** the variants object needs to be passed into the motion component **/
        variants={variants}
        /** it's right here that we match our boolean state with these variant keys **/
        // animate={hidden ? "hidden" : "visible"}
        /** I'm also going to add a custom easing curve and duration for the animation **/
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        /** basic nav styles **/
        style={navStyles}
      >
        <nav
          className={`z-50 rounded-b-sm min-h-[92px] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] fixed top-0 flex w-full flex-wrap items-center justify-between transition-all lg:flex-wrap lg:justify-start`}
          data-te-navbar-ref
          onMouseLeave={() => setActiveSubMenu(null)}
          style={{ background: "rgb(2 56 69 / 93%)" }}
        >
          <div className="m-auto flex w-full flex-wrap items-center justify-between px-6">
            <motion.button whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }} className="cursor-pointer">
              <Link to="/" className="flex">
                <motion.img
                  src="/images/logo-white.png"
                  alt="Agimun"
                  className="w-[200px] lg:w-[300px]"
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                />
              </Link>
            </motion.button>

            <button
              className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
              type="button"
              onClick={handleToggle}
              data-te-collapse-init
              data-te-target="#navbarSupportedContent8"
              aria-controls="navbarSupportedContent8"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="[&>svg]:w-7">
                {isToggled ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" className="h-7 w-7">
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </span>
            </button>

            <div
              className="!visible mt-2 hidden flex-grow basis-[100%] lg:mt-0 lg:!flex lg:basis-auto justify-end items-center ml-6"
              id="navbarSupportedContent8"
              data-te-collapse-item
            >
              <ul className="list-style-none flex flex-col pl-0 lg:flex-row text-center font-bold text-lg" data-te-navbar-nav-ref>
                {menuItems.map((menuItem) => (
                  <li key={menuItem.id} className={`relative my-4 px-4`} data-te-nav-item-ref>
                    <Link
                      to={menuItem.path}
                      className={`mainMenuLink relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-white after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] hover:after:origin-bottom-left ${
                        isToggled ? "text-3xl" : ""
                      } hover:after:scale-x-100 p-0 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-white motion-reduce:transition-none mx-2 font-['Roboto_Condensed'] uppercase ${
                        activeLink === menuItem.path ? "text-white" : "text-white"
                      }`}
                      data-te-nav-link-ref
                      onMouseEnter={() => setActiveSubMenu(menuItem.id)}
                    >
                      {menuItem.label}
                      {menuItem.subMenus && activeSubMenu === menuItem.id && (
                        <ul className="absolute left-0 top-[58px] bg-master-300 py-2 w-[280px]">
                          {menuItem.subMenus.map((subMenu) => (
                            <li key={subMenu.id} className="">
                              <Link to={subMenu.path} className="block px-4 py-2 text-sm text-left text-white hover:bg-master-100">
                                {subMenu.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>

              <motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: "20%" }}
                transition={{ duration: 0.5 }}
                className="relative"
                ref={buttonRef}
              >
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  onClick={handleButtonClick}
                  className="w-9 h-9 rounded-full drop-shadow-md right-[-40px] border border-3 cursor-pointer"
                >
                  <div className="text-xs text-white justify-center items-center flex">
                    <svg class="w-[16px] h-[16px] fill-[#fff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"></path>
                    </svg>
                  </div>
                </motion.button>

                {showLanguages && (
                  <motion.div
                    initial={{ opacity: 0, y: "-100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "20%" }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="absolute top-4 right-0 bg-white p-2 rounded-md shadow-md flex flex-col">
                      {languages.map((lang) => (
                        <div
                          key={lang}
                          onClick={() => changeLanguage(lang.code)}
                          className="text-xs flex flex-row justify-center items-center text-center cursor-pointer uppercase px-2 py-3 hover:bg-master-200 transition-bg duration-300 rounded-sm"
                        >
                          <span className="text-lg mr-1">{lang.flag}</span>
                          <span>{lang.code}</span>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </div>
          </div>
        </nav>

        <AnimatePresence>
          {isToggled && showButton && (
            <motion.div
              className="navbar fixed z-10 w-full h-dvh flex flex-col items-center justify-between top-0 mt-6"
              initial="hidden"
              animate={isToggled ? "visible" : "hidden"}
              exit="hidden"
              variants={navContainer}
              style={{ background: "linear-gradient(rgb(63 120 126), rgb(22, 40, 76))" }}
            >
              <p></p>

              <ul className="list-style-none flex flex-col pl-0 lg:flex-row text-center font-bold text-lg" data-te-navbar-nav-ref>
                {menuItems.map((menuItem) => (
                  <li key={menuItem.id} className={`relative my-4 px-4`} data-te-nav-item-ref>
                    {menuItem.subMenus ? (
                      <div className="mobile-menu">
                        <Link
                          onClick={toggleSubMenu}
                          className={`mainMenuLink flex flex-row justify-center items-center relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-white after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] ${
                            isToggled ? "text-3xl" : ""
                          } p-0 motion-reduce:transition-none mx-2 font-['Roboto_Condensed'] uppercase ${
                            activeLink === menuItem.path ? "text-white" : "text-white"
                          }`}
                        >
                          <motion.svg
                            className="w-[16px] h-[16px] fill-[#fff] mr-2"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                            animate={{ rotate: showSubMenu ? 180 : 0 }}
                          >
                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z"></path>
                          </motion.svg>{" "}
                          {menuItem.label}
                        </Link>
                        <div className={`sub-menu-container ${showSubMenu ? "open" : ""}`}>
                          {menuItem.subMenus && (
                            <ul className="sub-menu left-0 top-[58px] bg-master-300 py-2 w-[280px]">
                              {menuItem.subMenus.map((subMenu) => (
                                <li key={subMenu.id} className="">
                                  <Link to={subMenu.path} className="block text-center px-4 py-2 text-sm text-white">
                                    {subMenu.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={menuItem.path}
                        className={`mainMenuLink relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 after:bg-white after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65_0.05_0.36_1)] ${
                          isToggled ? "text-3xl" : ""
                        } p-0 focus:text-white motion-reduce:transition-none mx-2 font-['Roboto_Condensed'] uppercase ${
                          activeLink === menuItem.path ? "text-white" : "text-white"
                        }`}
                        data-te-nav-link-ref
                        onMouseEnter={() => setActiveSubMenu(menuItem.id)}
                      >
                        {menuItem.label}
                      </Link>
                    )}
                  </li>
                ))}

                <div className="mt-8">
                  <motion.div
                    initial={{ opacity: 0, y: "-100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "20%" }}
                    transition={{ duration: 0.5 }}
                    className="flex flex-row justify-end items-center relative h-[46px]"
                    ref={buttonRef}
                  >
                    {showLanguages && (
                      <motion.div
                        initial={{ opacity: 0, y: "-10" }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: "20%" }}
                        transition={{ duration: 0.5 }}
                      >
                        <div className="px-2 bg-master-200 mr-3 rounded-md shadow-md flex flex-row">
                          {languages.map((lang) => (
                            <div
                              key={lang}
                              onClick={() => changeLanguage(lang.code)}
                              className="text-xs flex flex-col justify-center items-center text-center cursor-pointer uppercase mx-1 px-2 py-1 hover:bg-master-200 transition-bg duration-300 rounded-sm"
                            >
                              <span className="text-lg">{lang.flag}</span>
                              <span>{lang.code}</span>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    )}

                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      onClick={handleButtonClick}
                      className="w-9 h-9 rounded-full drop-shadow-md right-[-40px] border border-3 cursor-pointer"
                    >
                      <div className="text-xs text-white justify-center items-center flex">
                        <svg class="w-[16px] h-[16px] fill-[#fff]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                          <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"></path>
                        </svg>
                      </div>
                    </motion.button>
                  </motion.div>
                </div>
              </ul>

              <p></p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>
    </div>
  );
};

export default NavBar;
