import React from "react";
import { useTranslation } from "react-i18next";

import CustomStatCard from "./elements/CustomStatCard";

const OurStats = ({ isVisible }) => {
  const { t } = useTranslation();

  return (
    <div className="container max-w-screen-xl mx-auto px-6 md:px-12 xl:px-32 mt-12">
      <section className="text-center">
        {/* 🟢 Header */}

        <div className="grid pt-24 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <CustomStatCard
            imageSrc="/images/law-white.png"
            altText={t("ourStat1")}
            value={4000}
            title="+"
            description={t("ourStat1")}
            isPrimary={false}
            isVisible={isVisible}
          />
          <CustomStatCard
            imageSrc="/images/partners-white.png"
            altText={t("ourStat2")}
            value={25}
            title=""
            description={t("ourStat2")}
            isPrimary={false}
            isVisible={isVisible}
          />
          <CustomStatCard
            imageSrc="/images/justice-scale-white.png"
            altText={t("ourStat3")}
            value={200}
            title="+"
            description={t("ourStat3")}
            isPrimary={false}
            isVisible={isVisible}
          />
          <CustomStatCard
            imageSrc="/images/course-white.png"
            altText={t("ourStat4")}
            value={1000}
            title="+"
            description={t("ourStat4")}
            isPrimary={false}
            isVisible={isVisible}
          />
        </div>
      </section>
    </div>
  );
};

export default OurStats;
