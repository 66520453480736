import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TeamFull = ({ data }) => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="">
      {isMobile ? (
        <div className="flex pt-16 mb-16 bg-[#daf0f0]">
          <div className="container max-w-screen-lg mx-auto flex flex-col justify-between">
            <div className="flex flex-col justify-center items-start px-12">
              <div className="border-l-4 border-master-300 pl-8">
                <h1
                  className="text-2xl md:text-5xl font-geologica uppercase bg-gradient-to-b text-primary-900 bg-clip-text"
                  style={{ lineHeight: "normal" }}
                >
                  {data.lname}
                </h1>
                <h1 className="text-2xl md:text-5xl font-geologica uppercase font-light mb-4  bg-gradient-to-b text-primary-900 bg-clip-text">
                  {data.fname}
                </h1>
                <h3 className="text-xl md:text-3xl text-master-100 font-geologica">{data.role}</h3>
              </div>
            </div>

            <div className="px-12 mt-12 flex items-center justify-center">
              <img src={data.imagePath} className="max-h-[600px] z-[10]" alt={`${data.lname} ${data.fname}`} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex pt-16 mb-16 bg-[#daf0f0]">
          <div className="container max-w-screen-lg mx-auto flex flex-row justify-between">
            <div className="flex flex-col justify-center items-start px-6">
              <div className="border-l-4 border-master-300 pl-8">
                <h1
                  className="text-4xl sm:text-3xl lg:text-5xl font-geologica uppercase bg-gradient-to-b text-primary-900 bg-clip-text"
                  style={{ lineHeight: "normal" }}
                >
                  {data.lname}
                </h1>
                <h1 className="text-4xl sm:text-3xl lg:text-5xl font-geologica uppercase font-light mb-4  bg-gradient-to-b text-primary-900 bg-clip-text">
                  {data.fname}
                </h1>
                <h3 className="text-3xl text-master-100 font-geologica">{data.role}</h3>
              </div>
            </div>

            <div className="px-12">
              <img src={data.imagePath} className="w-auto max-h-[600px] z-[10]" alt={`${data.lname} ${data.fname}`} />
            </div>
          </div>
        </div>
      )}

      {/*
        ||
        || 🟩 Bio
        ||
        */}
      <div className="container max-w-screen-lg mx-auto flex flex-col">
        <div className="flex flex-wrap">
          <div className={`w-full flex ${isMobile ? "flex-col" : "flex-row"}`}>
            <div className={`flex flex-col px-6 ${isMobile ? "w-full" : "w-[60%]"}`}>
              {/* 🟩 description */}
              {data.description && data.description.length > 0 && (
                <div>
                  {data.description.map((descriptionLevel, index) => (
                    <p key={`description_${index}`} className="mb-8 pb-2 text-primary-700 lg:pb-0">
                      {descriptionLevel}
                    </p>
                  ))}
                </div>
              )}
            </div>

            <div className={`flex flex-col px-6 ${isMobile ? "w-full" : "w-[40%]"}`}>
              {/* 🟩 proficiency */}
              {data.proficiency && data.proficiency.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-primary-800 uppercase font-geologica text-xl">{t("proficiency")}</h3>

                  <ul className="list-outside list-disc pl-4 mb-4 text-left">
                    {data.proficiency.map((proficiencyLevel, index) => (
                      <li key={`proficiency_${index}`} className="text-primary-700">
                        {proficiencyLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* 🟩 education */}
              {data.education && data.education.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-primary-800 uppercase font-geologica text-xl">{t("education")}</h3>

                  <ul className="list-outside list-disc pl-4 mb-4 text-left">
                    {data.education.map((educationLevel, index) => (
                      <li key={`education_${index}`} className="text-primary-700">
                        {educationLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* 🟩 workxp */}
              {data.workxp && data.workxp.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-primary-800 uppercase font-geologica text-xl">{t("workxp")}</h3>

                  <ul className="list-outside list-disc pl-4 mb-4 text-left">
                    {data.workxp.map((workxpLevel, index) => (
                      <li key={`workxp_${index}`} className="text-primary-700">
                        {workxpLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* 🟩 skills */}
              {data.skills && data.skills.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-primary-800 uppercase font-geologica text-xl">{t("skills")}</h3>

                  <ul className="list-outside list-disc pl-4 mb-4 text-left">
                    {data.skills.map((skillsLevel, index) => (
                      <li key={`proficiency_${index}`} className="text-primary-700">
                        {skillsLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* 🟩 membership */}
              {data.membership && data.membership.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-primary-800 uppercase font-geologica text-xl">{t("membership")}</h3>

                  <ul className="list-outside list-disc pl-4 mb-4 text-left">
                    {data.membership.map((membershipLevel, index) => (
                      <li key={`proficiency_${index}`} className="text-primary-700">
                        {membershipLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* 🟩 membership */}
              {data.languageSkills && data.languageSkills.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-primary-800 uppercase font-geologica text-xl">{t("languageSkills")}</h3>

                  <ul className="list-outside list-disc pl-4 mb-4 text-left">
                    {data.languageSkills.map((languageSkillsLevel, index) => (
                      <li key={`proficiency_${index}`} className="text-primary-700">
                        {languageSkillsLevel}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamFull;
