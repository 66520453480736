import React, { useState, useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import { ScrollPage } from "react-scroll-motion";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CustomHeader from "../components/elements/CustomHeader";
import OurStats from "../components/OurStats";
import OurMission from "../components/OurMission";
import OurTestimonials from "../components/OurTestimonials";
import CustomCard from "../components/elements/CustomCard";
import BannerHeader from "../components/elements/BannerHeader";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const [refStats, statsInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [refWelcome, inViewWelcome] = useInView({ triggerOnce: false, threshold: 0.2 });

  const videoRefWelcome = useRef(null);

  const [showAfter1sec, setAhowAfter1sec] = useState(false);

  useEffect(() => {
    const timeoutId1 = setTimeout(() => {
      setAhowAfter1sec(true);
    }, 1000);

    return () => clearTimeout(timeoutId1);
  }, []);

  //
  // Start playing video when its visible on screen
  //
  useEffect(() => {
    if (inViewWelcome) {
      // Play the video when it becomes visible
      videoRefWelcome.current.play();
    } else {
      // Pause the video when it goes out of view
      videoRefWelcome.current.pause();
    }
  }, [inViewWelcome]);

  const cardVariants = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 2,
        delay: 0.2,
      },
    },
  };

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1.2, ease: "easeOut" },
    });
  }, [controls]);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;
      setIsScrolled(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const controlsScrollButton = useAnimation();
  useEffect(() => {
    controlsScrollButton.start({ opacity: isScrolled ? 0 : 1, y: isScrolled ? 50 : 0 });
  }, [isScrolled, controlsScrollButton]);

  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        duration: 0.4,
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-white"
    >
      <NavBar />

      <BannerHeader isPrimary={false} headerText={t("pageAbout")} bgImage={"all.jpg"} bottomSpace={false} titleBelow={true} />

      <motion.div className="card-container my-24" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }}>
        <motion.div className="card" variants={cardVariantsScaled}>
          <div className="flex flex-wrap max-w-screen-lg m-auto">
            <div className="w-full shrink-0 grow-0 basis-auto">
              <div className="flex flex-col h-full items-center rounded-lg p-6 lg:pl-12">
                <h1 className="text-master-300 uppercase text-3xl lg:text-5xl my-4 font-geologica text-center lg:text-left">{t("historyTitle")}</h1>

                <p className="max-w-screen-lg leading-relaxed mb-3 text-xl text-primary-900">{t("historyDesc1")}</p>
                <p className="max-w-screen-lg leading-relaxed mb-3 text-xl text-primary-900">{t("historyDesc2")}</p>
                <p className="max-w-screen-lg leading-relaxed mb-3 text-xl text-primary-900">{t("historyDesc3")}</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <ScrollPage className="gradientMasterSet relative w-full h-full flex flex-col justify-center items-center min-h-svh" style={{ height: "auto" }}>
        <div className="p-5 md:p-12">
          <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden" }}>
            <div className="absolute w-full h-full bg-master-300 opacity-45"></div>
            <video
              ref={videoRefWelcome}
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src="/images/law.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <motion.div initial={{ opacity: 0, y: -20 }} animate={controls} exit="never" className="z-20 relative" ref={refWelcome}>
            <motion.button
              style={{
                opacity: showAfter1sec ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                minHeight: "64px",
              }}
            >
              {showAfter1sec && (
                <div className="fadedText max-w-[1260px]">
                  <h1 className="text-white font-avertaCyrillic opacity-70">
                    <span className="uppercase">{t("fadedText1")}</span>
                    <span className="uppercase">{t("fadedText2")}</span>
                    <span className="uppercase">{t("fadedText3")}</span>
                    <span className="uppercase">{t("fadedText4")}</span>
                  </h1>
                </div>
              )}
            </motion.button>
          </motion.div>
        </div>
      </ScrollPage>

      {/*
      ||
      || 🟩 What we do
      ||
      */}
      <ScrollPage
        className="w-full h-full flex flex-col justify-center items-center min-h-svh"
        style={{ backgroundImage: "url(images/clouds.jpg)", height: "auto", backgroundAttachment: "fixed" }}
      >
        <div className="h-32"></div>
        <div className="px-5 md:px-12 py-24 md:py-12">
          <div className="bg-master-100 absolute top-0 left-0 w-full h-full opacity-35"></div>

          <div className="max-w-screen-lg p-4 md:p-12 justify-center items-center flex flex-col">
            <div
              className="max-w-screen-lg mt-[-150px] max-md:mt-[-100px] pt-10 rounded-3xl bg-[#21212154] shadow-[0_2px_60px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[20px] p-4 md:p-12"
              style={{ background: "linear-gradient(0deg, rgb(216, 242, 255) 0%, rgb(255 255 255) 100%)" }}
            >
              <motion.div className="justify-center items-center flex flex-col" initial="offscreen" whileInView="onscreen" variants={cardVariants}>
                <CustomHeader isPrimary={true} headerText={t("homeSection2Title")} />
              </motion.div>

              <section className="flex flex-col justify-center items-center">
                <div className="grid lg:grid-cols-2 pb-0 pt-10 text-center">
                  <CustomCard
                    imageSrc="images/pillar.png"
                    altText={t("customCardTitle1")}
                    title={t("customCardTitle1")}
                    description={t("customCardDesc1")}
                    isPrimary={true}
                  />

                  <CustomCard
                    imageSrc="images/arrows.png"
                    altText={t("customCardTitle2")}
                    title={t("customCardTitle2")}
                    description={t("customCardDesc2")}
                    isPrimary={true}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </ScrollPage>

      {/*
      ||
      || 🟩 Values
      ||
      */}
      <ScrollPage
        className="w-full h-full relative flex flex-col justify-center items-center min-h-svh"
        style={{ backgroundImage: "url(images/autumn-columns-blurred.jpg)", height: "auto" }}
      >
        <div className="px-5 md:px-12 py-24 md:py-12 relative z-10">
          <CustomHeader isPrimary={false} headerText={t("sectionTitle3")} />

          <OurMission />
        </div>
        <div className="polyStaticTopRight bg-master-100 opacity-10"></div>
        <div className="polyStaticBottLeft bg-master-100 opacity-10"></div>

        <div className="bg-primary-900 absolute w-full h-full opacity-80"></div>
      </ScrollPage>

      {/*
        ||
        || 🟩 Services
        ||
        */}
      <ScrollPage
        className="w-full h-full flex flex-col justify-center items-center bg-cover min-h-svh"
        style={{ backgroundImage: "url(images/abstract.jpg)", height: "auto" }}
      >
        <Parallax className="poly3" translateY={[-30, 30]}></Parallax>

        <div className="px-5 md:px-12 py-24 md:py-12 relative z-10">
          <CustomHeader isPrimary={false} headerText={t("ourSeriviceTitle")} />

          <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.6 }}>
            <motion.div className="card" variants={cardVariants}>
              <p className="lg:w-2/4 pt-10 mx-auto leading-relaxed text-white text-xl">{t("ourSeriviceDesc1")}</p>
              <p className="lg:w-2/4 pt-10 mx-auto leading-relaxed text-white text-xl">{t("ourSeriviceDesc2")}</p>

              <div className="flex flex-col">
                <Link
                  to="/services"
                  className="flex my-12 mx-auto border-4 border-white px-6 py-3 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:text-white focus:border-white focus:text-white focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic"
                  data-twe-ripple-init
                >
                  {t("moreInfo")}
                </Link>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </ScrollPage>

      {/*
        ||
        || 🟩 Testimonials
        ||
        */}
      <ScrollPage className="w-full" style={{ height: "auto" }}>
        <div className="gradientMasterSet w-full h-full flex flex-col justify-center items-center bg-cover min-h-svh">
          <div className="container max-w-screen-xl mx-auto px-6 md:px-12 xl:px-32">
            <div className="px-5 md:px-12 py-24 md:py-12">
              <CustomHeader isPrimary={false} headerText={t("customerReview")} />

              <OurTestimonials />
            </div>
          </div>
        </div>
      </ScrollPage>

      {/*
        ||
        || 🟩 Stats
        ||
        */}
      <div className="gradientMasterSet relative z-10 w-full flex flex-col justify-center items-center" ref={refStats}>
        <div className="p-12">
          <CustomHeader isPrimary={false} headerText={t("ourStatTitle")} />

          <motion.div key="OurStats" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <OurStats isVisible={statsInView} />
          </motion.div>
        </div>
      </div>

      <Footer showContactButton={true} />
    </motion.div>
  );
};

export default AboutPage;
