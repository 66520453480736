import { motion } from "framer-motion";
import React from "react";
import CountUp from "react-countup";

const CustomStatCard = ({ imageSrc, altText, value, title, description, isPrimary, isVisible }) => {
  const textColorClass = isPrimary ? "text-white" : "text-master-200";

  return (
    <div className="mb-16 lg:mb-0">
      <div className="flex justify-center">
        <div className="-mt-8 inline-block rounded-full bg-secondary-400 p-4 text-primary">
          <img src={imageSrc} alt={altText} width="64" height="64" />
        </div>
      </div>
      <div className="p-6">
        <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.6 }}>
          {isVisible && (
            <p className={`mb-4 text-6xl font-bold font-nunito text-white`}>
              {title}
              <CountUp decimal="" separator="" end={value} />
            </p>
          )}
        </motion.div>

        <p className={`text-sm ${textColorClass}`}>{description}</p>
      </div>
    </div>
  );
};

export default CustomStatCard;
