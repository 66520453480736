import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import BannerHeader from "../components/elements/BannerHeader";
import FollowUsSection from "../components/FollowUsSection";

const JoinUsPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        duration: 0.4,
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-white"
    >
      <NavBar />

      <BannerHeader headerText={t("joinUsTitle")} bgImage={"hallway.jpg"} bottomSpace={false} />

      <motion.div className="card-container my-24" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }}>
        <motion.div className="card" variants={cardVariantsScaled}>
          <div className="flex flex-wrap max-w-screen-lg m-auto">
            <div className="w-full shrink-0 grow-0 basis-auto">
              <div className="flex flex-col h-full items-center rounded-lg p-6 lg:pl-12">
                <h1 className="text-master-300 uppercase text-3xl lg:text-5xl my-4 font-geologica text-center lg:text-left">{t("joinUsSlogan")}</h1>

                <p className="mb-4 leading-relaxed text-primary-900 ">{t("joinUsDesc1")}</p>
                <p className="mb-4 leading-relaxed text-primary-900 ">{t("joinUsDesc2")}</p>
                <p className="mb-4 leading-relaxed text-primary-900 ">{t("joinUsDesc3")}</p>
                <p className="mb-4 leading-relaxed text-primary-900 ">{t("joinUsDesc4")}</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <FollowUsSection />

      <Footer showContactButton={true} />
    </motion.div>
  );
};

export default JoinUsPage;
