import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const ServiceBlock = ({ data, indexBlock }) => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cardVariantsLeft = {
    offscreen: {
      transform: "translateX(-500px)",
    },
    onscreen: {
      transform: "translateX(0)",
      transition: {
        type: "circInOut",
        duration: 1.4,
      },
    },
  };

  const cardVariantsRight = {
    offscreen: {
      transform: "translateX(500px)",
    },
    onscreen: {
      transform: "translateX(0)",
      transition: {
        type: "circInOut",
        duration: 1.4,
      },
    },
  };

  return (
    <div className="w-full relative">
      <motion.div
        className=""
        initial="offscreen"
        whileInView="onscreen"
        variants={indexBlock % 2 === 0 ? cardVariantsLeft : cardVariantsRight}
        viewport={{ once: true }}
      >
        <div className="flex flex-wrap items-center max-w-[2400px] m-auto bg-master-100 relative">
          {indexBlock % 2 === 0 ? ( // Decide whether the image should be on the left or right
            <>
              <div className="polyStaticOuterTL bg-master-200 opacity-40"></div>
              <div className="polyStaticOuterTR bg-master-200 opacity-40"></div>
              <div className="polyStaticOuterBL bg-master-100 opacity-30"></div>

              <div className="mt-[-50px] lg:mt-0 m-auto relative z-10 shrink-0 grow-0 basis-auto lg:flex w-4/5 lg:w-1/2 bg-center bg-cover">
                <img src={data.imageSrc} alt="Trendy Pants and Shoes" className="resizable-image" />
              </div>
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-1/2">
                <div className="px-6 py-12 md:px-12 text-center relative z-10">
                  <h2 className="mb-4 text-4xl font-geologica text-white">{data.title}</h2>
                  <Link
                    to={`/services/${data.url}`}
                    className="inline-block border-4 border-white px-6 py-3 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:text-white focus:border-white focus:text-white focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic"
                    data-twe-ripple-init
                  >
                    {t("moreInfo")}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="polyStaticOuterTL bg-master-200 opacity-40"></div>
              <div className="polyStaticOuterTR bg-master-200 opacity-40"></div>
              <div className="polyStaticOuterBL bg-master-100 opacity-30"></div>

              {isMobile && (
                <div className="mt-[-50px] lg:mt-0 m-auto relative z-10 shrink-0 grow-0 basis-auto lg:flex w-4/5 lg:w-1/2 bg-center bg-cover">
                  <img src={data.imageSrc} alt="Trendy Pants and Shoes" className="resizable-image" />
                </div>
              )}
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-1/2">
                <div className="px-6 py-12 md:px-12 text-center relative z-10">
                  <h2 className="mb-4 text-4xl font-geologica text-white">{data.title}</h2>
                  <Link
                    to={`/services/${data.url}`}
                    className="inline-block border-4 border-white px-6 py-3 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:text-white focus:border-white focus:text-white focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic"
                    data-twe-ripple-init
                  >
                    {t("moreInfo")}
                  </Link>
                </div>
              </div>
              {!isMobile && (
                <div className="mt-[-50px] lg:mt-0 m-auto relative z-10 shrink-0 grow-0 basis-auto lg:flex w-4/5 lg:w-1/2 bg-center bg-cover">
                  <img src={data.imageSrc} alt="Trendy Pants and Shoes" className="resizable-image" />
                </div>
              )}
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ServiceBlock;
