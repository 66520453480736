import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CustomHeader from "../components/elements/CustomHeader";
import OurStats from "../components/OurStats";
import TeamFull from "./TeamFull";

const TeamMember = () => {
  const { t } = useTranslation();
  const [refStats, statsInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const member = window.location.pathname.split("/")[1];

  const membersData = [
    {
      url: "Shinegerel",
      imagePath: "./images/Shinegerel.jpg",
      lname: t("teamShinegerelLname"),
      fname: t("teamShinegerelFname"),
      role: t("teamShinegerelTitle"),
      //
      description: [
        t("teamShinegerelDesc1"),
        t("teamShinegerelDesc2"),
        t("teamShinegerelDesc3"),
      ],
      proficiency: [
        t("teamShinegerelProficiency1"),
        t("teamShinegerelProficiency2"),
      ],
      education: [],
      workxp: [],
      skills: [t("teamShinegerelSkill1")],
      membership: [
        t("teamShinegerelMembership1"),
        t("teamShinegerelMembership2"),
        t("teamShinegerelMembership3"),
      ],
      languageSkills: [],
    },
    {
      url: "Guljan",
      imagePath: "./images/Guljan.jpg",
      lname: t("teamGuljanLname"),
      fname: t("teamGuljanFname"),
      role: t("teamGuljanTitle"),
      //
      description: [
        t("teamGuljanDesc1"),
        t("teamGuljanDesc2"),
        t("teamGuljanDesc3"),
      ],
      proficiency: [t("teamGuljanProficiency1"), t("teamGuljanProficiency2")],
      education: [
        t("teamGuljanEducation1"),
        t("teamGuljanEducation2"),
        t("teamGuljanEducation3"),
      ],
      workxp: [],
      skills: [t("teamGuljanSkill1")],
      membership: [t("teamGuljanMembership1")],
      languageSkills: [],
    },
    {
      url: "Bilguuntugs",
      imagePath: "./images/Bilguuntugs.jpg",
      lname: t("teamBilguuntugsLname"),
      fname: t("teamBilguuntugsFname"),
      role: t("teamBilguuntugsTitle"),
      //
      description: [t("teamBilguuntugsDesc1"), t("teamBilguuntugsDesc2")],
      proficiency: [],
      education: [
        t("teamBilguuntugsEducation1"),
        t("teamBilguuntugsEducation2"),
      ],
      workxp: [t("teamBilguuntugsWorkxp1")],
      skills: [],
      membership: [],
      languageSkills: [t("teamBilguuntugsLanguageSkills1")],
    },
    {
      url: "Bathuu",
      imagePath: "./images/Bathuu.jpg",
      lname: t("teamBathuuLname"),
      fname: t("teamBathuuFname"),
      role: t("teamBathuuTitle"),
      //
      description: [t("teamBathuuDesc1")],
      proficiency: [],
      education: [],
      workxp: [
        t("teamBathuuWorkxp1"),
        t("teamBathuuWorkxp2"),
        t("teamBathuuWorkxp3"),
        t("teamBathuuWorkxp4"),
        t("teamBathuuWorkxp5"),
        t("teamBathuuWorkxp6"),
        t("teamBathuuWorkxp7"),
      ],
      skills: [],
      membership: [],
      languageSkills: [
        t("teamBathuuLanguageSkills1"),
        t("teamBathuuLanguageSkills2"),
      ],
    },
    {
      url: "Azbayar",
      imagePath: "./images/Azbayar.jpg",
      lname: t("teamAzbayarLname"),
      fname: t("teamAzbayarFname"),
      role: t("teamAzbayarTitle"),
      //
      description: [
        t("teamAzbayarDesc1"),
        t("teamAzbayarDesc2"),
        t("teamAzbayarDesc3"),
        t("teamAzbayarDesc4"),
      ],
      proficiency: [t("teamAzbayarProficiency1"), t("teamAzbayarProficiency2")],
      education: [],
      workxp: [],
      skills: [
        t("teamAzbayarSkill1"),
        t("teamAzbayarSkill2"),
        t("teamAzbayarSkill3"),
      ],
      membership: [
        t("teamAzbayarMembership1"),
        t("teamAzbayarMembership2"),
        t("teamAzbayarMembership3"),
      ],
      languageSkills: [],
    },
    {
      url: "Otgonjargal",
      imagePath: "./images/Otgonjargal.jpg",
      lname: t("teamOtgonjargalLname"),
      fname: t("teamOtgonjargalFname"),
      role: t("teamOtgonjargalTitle"),
      //
      description: [
        t("teamOtgonjargalDesc1"),
        t("teamOtgonjargalDesc2"),
        t("teamOtgonjargalDesc3"),
      ],
      proficiency: [
        t("teamOtgonjargalProficiency1"),
        t("teamOtgonjargalProficiency2"),
        t("teamOtgonjargalProficiency3"),
        t("teamOtgonjargalProficiency4"),
      ],
      education: [
        t("teamOtgonjargalEducation1"),
        t("teamOtgonjargalEducation2"),
        t("teamOtgonjargalEducation3"),
        t("teamOtgonjargalEducation4"),
        t("teamOtgonjargalEducation5"),
      ],
      workxp: [],
      skills: [],
      membership: [],
      languageSkills: [
        t("teamOtgonjargalLanguageSkills1"),
        t("teamOtgonjargalLanguageSkills2"),
      ],
    },
    {
      url: "Altantsetseg",
      imagePath: "./images/Altantsetseg.jpg",
      lname: t("teamAltantsetsegLname"),
      fname: t("teamAltantsetsegFname"),
      role: t("teamAltantsetsegTitle"),
      //
      description: [
        t("teamAltantsetsegDesc1"),
        t("teamAltantsetsegDesc2"),
        t("teamAltantsetsegDesc3"),
        t("teamAltantsetsegDesc4"),
        t("teamAltantsetsegDesc5"),
        t("teamAltantsetsegDesc6"),
        t("teamAltantsetsegDesc7"),
      ],
      proficiency: [
        t("teamAltantsetsegProficiency1"),
        t("teamAltantsetsegProficiency2"),
      ],
      education: [
        t("teamAltantsetsegEducation1"),
        t("teamAltantsetsegEducation2"),
        t("teamAltantsetsegEducation3"),
      ],
      workxp: [],
      skills: [],
      membership: [],
      languageSkills: [],
    },
    {
      url: "Narantsetseg",
      imagePath: "./images/Narantsetseg.jpg",
      lname: t("teamNarantsetsegLname"),
      fname: t("teamNarantsetsegFname"),
      role: t("teamNarantsetsegTitle"),
      //
      description: [
        t("teamNarantsetsegDesc1"),
        t("teamNarantsetsegDesc2"),
        t("teamNarantsetsegDesc3"),
        t("teamNarantsetsegDesc4"),
      ],
      proficiency: [t("teamNarantsetsegProficiency1")],
      education: [t("teamNarantsetsegEducation1")],
      workxp: [],
      skills: [],
      membership: [t("teamNarantsetsegMembership1")],
      languageSkills: [],
    },
    {
      url: "Tsolmon",
      imagePath: "./images/Tsolmon.jpg",
      lname: t("teamTsolmonLname"),
      fname: t("teamTsolmonFname"),
      role: t("teamTsolmonTitle"),
      //
      description: [t("teamTsolmonDesc1"), t("teamTsolmonDesc2")],
      proficiency: [t("teamTsolmonProficiency1")],
      education: [t("teamTsolmonEducation1")],
      workxp: [],
      skills: [],
      membership: [],
      languageSkills: [t("teamTsolmonLanguageSkills1")],
    },
    {
      url: "Hulan",
      imagePath: "./images/Hulan.jpg",
      lname: t("teamHulanLname"),
      fname: t("teamHulanFname"),
      role: t("teamHulanTitle"),
      //
      description: [
        t("teamHulanDesc1"),
        t("teamHulanDesc2"),
        t("teamHulanDesc3"),
      ],
      proficiency: [t("teamHulanProficiency1")],
      education: [],
      workxp: [t("teamHulanWorkxp1"), t("teamHulanWorkxp2")],
      skills: [],
      membership: [],
      languageSkills: [],
    },
  ];

  const selectedMember = membersData.find((m) => m.url === member);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <NavBar />

      <div className="mb-24">
        <div className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[92px]"></div>

        <TeamFull data={selectedMember} />

        <div className="flex flex-col items-center justify-center mt-24">
          <div className="containerHoverButton">
            <div className="flex flex-col">
              <Link
                to="/team"
                className="flex my-12 mx-auto border-4 border-master-100 px-6 py-3 text-md font-medium uppercase leading-normal text-master-100 transition duration-150 ease-in-out hover:text-master-300 focus:border-master-300 focus:text-master-100 focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic"
                data-twe-ripple-init
              >
                {t("ourTeamTitle")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*
        ||
        || 🟩 Stats
        ||
        */}
      <div
        className="gradientMasterSet w-full flex flex-col justify-center items-center"
        ref={refStats}
      >
        <div className="p-12">
          <CustomHeader isPrimary={false} headerText={t("ourStatTitle")} />

          <motion.div
            key="OurStats"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Parallax translateY={-[40, 40]}>
              <OurStats isVisible={statsInView} />
            </Parallax>
          </motion.div>
        </div>
      </div>

      <Footer showContactButton={true} />
    </motion.div>
  );
};

export default TeamMember;
