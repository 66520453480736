import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import { sendContactForm } from "../api";

const ContactUs = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isContactSent, setIsContactSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // ======================================================================== //
  // 🟨 Functions

  const handleRecaptchaVerify = (response) => {
    if (response) {
      setRecaptchaVerified(true);
    }
  };

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();

    const isValid = validateAllFormInput();

    if (isRecaptchaVerified && isValid) {
      console.log("Form submitted successfully");
      console.log(formData);

      setIsSending(true);

      const isEmailSent = await sendContactForm(formData.name, formData.email, formData.phone, formData.message);

      if (isEmailSent) {
        setIsContactSent(true);
        setIsSending(false);
      } else {
        setIsContactSent(true);
        setIsSending(false);
      }
    } else {
      console.log("Please complete the reCAPTCHA challenge.");
    }
  };

  const validateAllFormInput = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (document.getElementById("floatingInputEmail")) {
      const emailInput = document.getElementById("floatingInputEmail");
      const emailValue = emailInput.value;

      if (!emailRegex.test(emailValue)) {
        // Handle invalid email
        console.log("Invalid email format");
        // You can also set an error state or display an error message
        setIsValidEmail(false);

        return false;
      } else {
        // Email is valid
        console.log("Email is valid");
        setIsValidEmail(true);

        return true;
      }
    }

    // Handle the case when the element with id 'floatingInputEmail' is not found
    return false;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Update the relevant property in formData

    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(value);

    if (formData.email !== "") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const valid = emailRegex.test(formData.email);

      setIsValidEmail(valid);
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <div className="block max-w-[480px] mx-auto px-6">
      <h2 className="font-geologica text-white mb-12 text-4xl font-light leading-tight tracking-tight uppercase text-center">
        {t("contatctUsTitle")}
        <span className="block h-4 border-b-2 w-24 mt-[-10px] mx-auto border-primary-600"></span>
      </h2>

      {isContactSent ? (
        <div>
          <div className="flex space-x-4 justify-center">
            <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 fill-lime-600">
              <path
                fillRule="evenodd"
                d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M15.2928932,8.29289322 L10,13.5857864 L8.70710678,12.2928932 C8.31658249,11.9023689 7.68341751,11.9023689 7.29289322,12.2928932 C6.90236893,12.6834175 6.90236893,13.3165825 7.29289322,13.7071068 L9.29289322,15.7071068 C9.68341751,16.0976311 10.3165825,16.0976311 10.7071068,15.7071068 L16.7071068,9.70710678 C17.0976311,9.31658249 17.0976311,8.68341751 16.7071068,8.29289322 C16.3165825,7.90236893 15.6834175,7.90236893 15.2928932,8.29289322 Z"
              />
            </svg>
          </div>
          <p className="text-white text-center">{t("inputSuccessMessage")}</p>
        </div>
      ) : (
        <form onSubmit={onSubmitWithReCAPTCHA} autoComplete="off">
          <div className="relative mb-6" data-te-input-wrapper-init>
            <div className="relative mb-3">
              <input
                id="floatingInputName"
                type="text"
                name="name"
                value={formData.name || ""}
                onChange={handleInputChange}
                placeholder="Name"
                className="peer bg-white text-black m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight selection:transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:outline-none peer-focus:text-primary-600 [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
              />
              <label
                htmlFor="floatingInputName"
                className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary-600 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none"
              >
                {t("inputName")}
              </label>
            </div>
            <div className="relative mb-3">
              <input
                id="floatingInputPhone"
                type="phone"
                name="phone"
                value={formData.phone || ""}
                onChange={handleInputChange}
                placeholder="Phone"
                className="peer bg-white text-black m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight selection:transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:outline-none peer-focus:text-primary-600 [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
              />
              <label
                htmlFor="floatingInputName"
                className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary-600 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none"
              >
                {t("inputPhone")}
              </label>
            </div>
            <div className="relative mb-3">
              <input
                id="floatingInputEmail"
                type="email"
                name="email"
                value={formData.email || ""}
                onChange={handleInputChange}
                placeholder="name@example.com"
                className="peer bg-white text-black m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight selection:transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:outline-none peer-focus:text-primary-600 [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
              />
              <label
                htmlFor="floatingInputName"
                className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary-600 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none"
              >
                {t("inputEmail")}
              </label>
            </div>
            <div className="relative mb-3">
              <textarea
                id="floatingInputMessage"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Message"
                rows={5}
                className="peer h-auto bg-white text-black m-0 block w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight selection:transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:outline-none peer-focus:text-primary-600 [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
              ></textarea>
              <label
                htmlFor="floatingInputName"
                className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary-600 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none"
              >
                {t("inputMessage")}
              </label>
            </div>

            {isValidEmail && (
              <div className="my-6 flex justify-center">
                <ReCAPTCHA onChange={handleRecaptchaVerify} theme="white" sitekey="6LddM_woAAAAANsyP_X2Nc-B_RG29skG_59q1WCi" />
              </div>
            )}
          </div>

          {isSending ? (
            <div className="flex flex-col justify-center items-center">
              <div
                className="inline-block text-white h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div
                className={`containerHoverButton p-0 ${!isRecaptchaVerified || !isValidEmail ? "opacity-30" : "opacity-100"}`}
                style={{ padding: "0px" }}
              >
                <button
                  disabled={!isRecaptchaVerified || !isValidEmail}
                  type="submit"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block border-4 border-white px-6 py-3 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:text-white focus:border-white focus:text-white focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic"
                  data-sm-link-text={t("inputSubmit")}
                >
                  <span>{t("inputSubmit")}</span>
                </button>
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ContactUs;
