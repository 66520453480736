import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = ({ showContactButton }) => {
  const { t } = useTranslation();
  const sectionControls = useAnimation();

  return (
    <div className="min-w-full bg-master-100">
      <div
        className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%]"
        style={{ background: "linear-gradient(rgba(0, 0, 0, 0.1), rgb(74 151 163))" }}
      >
        <motion.div
          initial={{ rotate: -45, scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full h-0 border-solid border-t-8 border-r-8 border-transparent"
        />

        <motion.section
          initial="hidden"
          animate={sectionControls}
          transition={{ duration: 0.2 }}
          className="relative flex justify-between items-center bg-center bg-cover flex-col p-5"
        >
          {showContactButton ? (
            <main className="grid place-items-center text-white hover:text-primary-900 mt-10 mb-10">
              <article className="overflow-hidden p-px relative rounded-3xl bg-primary-900 shadow-xl">
                <div className="glow inset-0 w-[100px] h-[100px] absolute rotate-45"></div>
                <Link to="/contact" rel="noreferrer">
                  <section className="contactUsNow inline-block space-y-2 bg-primary-800 rounded-3xl z-10 relative px-7 py-5">
                    <h1 className="uppercase text-xl md:text-2xl font-nunito leading-normal">{t("pageContact")}</h1>
                  </section>
                </Link>
              </article>
            </main>
          ) : (
            <div className="mt-10"></div>
          )}

          <div className="relative z-10 flex flex-col">
            <motion.div className="block m-auto" whileHover={{ scale: 1.1 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
              <a
                href="https://www.facebook.com/profile.php?id=100069452176803"
                target="_blank"
                rel="noreferrer"
                className="transition-all duration-300 hover:text-white hover:underline cursor-pointer flex mx-auto"
                style={{
                  width: "36px",
                  height: "36px",
                  backgroundImage: `url('data:image/svg+xml,<%3Fxml version="1.0"%3F><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="36px" height="36px"><path fill="rgb(239, 239, 229)" d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"/></svg>')`,
                }}
              >
                <span className="sr-only">BLF - Facebook</span>
              </a>
            </motion.div>
            <p className="w-2/3 pt-10 mx-auto leading-relaxed max-[640px]:leading-normal text-primary-900 text-center flex flex-col">
              <span className="uppercase text-[9px] font-bold font-['Roboto_Condensed'] text-white">{t("inputPhone")}: </span>
              <a href="tel:+97676096032" className="transition-all duration-300 hover:text-white cursor-pointer max-[640px]:text-sm">
                (976) 7609-6032
              </a>

              <a href="tel:+97699998666" className="transition-all duration-300 hover:text-white cursor-pointer max-[640px]:text-sm">
                (976) 9999-8666
              </a>
            </p>
            <p className="max-w-screen-sm mx-auto leading-relaxed max-[640px]:leading-normal text-center">
              <span className="uppercase text-[9px] font-bold font-['Roboto_Condensed'] text-white">{t("addressTitle")}: </span>
              <a
                href="https://www.google.com/maps/search/?api=1&query=Монгол%20улс,%20Улаанбаатар%20хот,%20Сүхбаатар%20дүүрэг,%201-р%20хороо,%20Энхтайвны%20өргөн%20чөлөө,%20Blue%20Sky%20tower"
                target="_blank"
                rel="noreferrer"
                className="transition-all duration-300 text-primary-900 hover:text-white cursor-pointer max-[640px]:text-xs leading-tight flex flex-col"
              >
                <span>{t("address1")}</span>
                <span>{t("address2")}</span>
                <span>{t("address3")}</span>
              </a>
            </p>
          </div>

          <span className="text-[11px] text-primary-900 mt-10">© 2024 - BLF Business Law LLC</span>
        </motion.section>
      </div>
    </div>
  );
};

export default Footer;
