import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import NavBar from "../components/NavBar";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import BannerHeader from "../components/elements/BannerHeader";
import FollowUsSection from "../components/FollowUsSection";

const ContactPage = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 896);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 896);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-white"
    >
      <NavBar />

      <BannerHeader isPrimary={false} headerText={t("pageContact")} bgImage={"bluesky-night.jpg"} bottomSpace={false} />

      {isMobile ? (
        <div className="bg-master-200 w-full h-svh flex flex-col justify-center items-center min-h-svh">
          <div className="w-full h-full basis-auto justify-center items-center">
            <div className="py-12 relative z-10 w-full">
              <h2 className="text-3xl md:text-6xl mb-12 backdrop:max-md:text-4xl font-avertaCyrillic font-light leading-tight tracking-tight uppercase relative text-primary-900 text-center">
                {t("pageContact")}
              </h2>

              <div className="flex flex-row items-start mx-12">
                <div className="inline-block rounded-md bg-white p-4 text-primary mr-4">
                  <svg className="w-[30px] h-[30px] fill-primary-800" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
                  </svg>
                </div>

                <p className="leading-relaxed max-[640px]:leading-normal text-primary-900 flex flex-col">
                  <span className="uppercase text-2xl font-geologica text-white">{t("inputPhone")}: </span>
                  <a href="tel:+97676096032" className="transition-color text-xl duration-300 hover:text-white cursor-pointer">
                    (976) 7609-6032
                  </a>

                  <a href="tel:+97699998666" className="transition-color text-xl duration-300 hover:text-white cursor-pointe">
                    (976) 9999-8666
                  </a>
                </p>
              </div>

              <div className="h-12"></div>

              <div className="flex flex-row items-start mx-12">
                <div className="inline-block rounded-md bg-white p-4 text-primary mr-4">
                  <svg className="w-[30px] h-[30px] fill-primary-800" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"></path>
                  </svg>
                </div>
                <p className="max-w-screen-sm leading-relaxed max-[640px]:leading-normal">
                  <span className="uppercase text-2xl font-geologica text-white">{t("addressTitle")}: </span>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Монгол%20улс,%20Улаанбаатар%20хот,%20Сүхбаатар%20дүүрэг,%201-р%20хороо,%20Энхтайвны%20өргөн%20чөлөө,%20Blue%20Sky%20tower"
                    target="_blank"
                    rel="noreferrer"
                    className="transition-color duration-300 text-primary-900 text-xl hover:text-white cursor-pointer leading-tight flex flex-col"
                  >
                    <span>{t("address1")}</span>
                    <span>{t("address2")}</span>
                    <span>{t("address3")}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-full h-full basis-auto">
            <iframe
              title="ХАЯГ"
              src="https://maps.google.com/maps?q=Blue+Sky+Tower,Энх+Тайваны+Өргөн+Чөлөө+17,Ulaanbaatar+14240&t=&z=15&ie=UTF8&iwloc=&output=embed"
              className="h-full w-full"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ) : (
        <div className="bg-master-200 w-full h-svh flex flex-row justify-center items-center min-h-svh">
          <div className="w-full h-full basis-auto lg:w-2/3 flex justify-center items-center">
            <div className="px-6 py-12 md:px-12 relative z-10 max-w-lg w-full">
              <h2 className="text-3xl md:text-6xl mb-12 backdrop:max-md:text-4xl font-avertaCyrillic font-light leading-tight tracking-tight uppercase relative text-primary-900">
                {t("pageContact")}
              </h2>

              <div className="flex flex-row items-start">
                <div className="inline-block rounded-md bg-white p-4 text-primary mr-4">
                  <svg className="w-[30px] h-[30px] fill-primary-800" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
                  </svg>
                </div>

                <p className="leading-relaxed max-[640px]:leading-normal text-primary-900 flex flex-col">
                  <span className="uppercase text-2xl font-geologica text-white">{t("inputPhone")}: </span>
                  <a href="tel:+97676096032" className="transition-color text-xl duration-300 hover:text-white cursor-pointer max-[640px]:text-sm">
                    (976) 7609-6032
                  </a>

                  <a href="tel:+97699998666" className="transition-color text-xl duration-300 hover:text-white cursor-pointer max-[640px]:text-sm">
                    (976) 9999-8666
                  </a>
                </p>
              </div>

              <div className="h-12"></div>

              <div className="flex flex-row items-start">
                <div className="inline-block rounded-md bg-white p-4 text-primary mr-4">
                  <svg className="w-[30px] h-[30px] fill-primary-800" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"></path>
                  </svg>
                </div>
                <p className="max-w-screen-sm leading-relaxed max-[640px]:leading-normal">
                  <span className="uppercase text-2xl font-geologica text-white">{t("addressTitle")}: </span>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Монгол%20улс,%20Улаанбаатар%20хот,%20Сүхбаатар%20дүүрэг,%201-р%20хороо,%20Энхтайвны%20өргөн%20чөлөө,%20Blue%20Sky%20tower"
                    target="_blank"
                    rel="noreferrer"
                    className="transition-color duration-300 text-primary-900 text-xl hover:text-white cursor-pointer max-[640px]:text-xs leading-tight flex flex-col"
                  >
                    <span>{t("address1")}</span>
                    <span>{t("address2")}</span>
                    <span>{t("address3")}</span>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="w-full h-full basis-auto lg:w-1/3">
            <iframe
              title="ХАЯГ"
              src="https://maps.google.com/maps?q=Blue+Sky+Tower,Энх+Тайваны+Өргөн+Чөлөө+17,Ulaanbaatar+14240&t=&z=15&ie=UTF8&iwloc=&output=embed"
              className="h-full w-full"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}

      <div className="py-24 bg-cover bg-center relative" style={{ backgroundImage: "url(/images/office.jpg)" }}>
        <div className="bg-primary-900 absolute top-0 left-0 w-full h-full opacity-90"></div>
        <div className="relative z-10">
          <ContactUs />
        </div>
      </div>

      <FollowUsSection />

      <Footer showContactButton={false} />
    </motion.div>
  );
};

export default ContactPage;
