import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import StaggeredBlocks from "../../components/StaggeredBlocks";
import BannerHeader from "../../components/elements/BannerHeader";
import FollowUsSection from "../../components/FollowUsSection";
import JoinUsSection from "../../components/JoinUsSection";

const ContentManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        duration: 0.4,
      },
    },
  };

  const managementFields = [
    {
      title: t("managementFields1"),
    },
    {
      title: t("managementFields2"),
    },
    {
      title: t("managementFields3"),
    },
    {
      title: t("managementFields4"),
    },
    {
      title: t("managementFields5"),
    },
    {
      title: t("managementFields6"),
    },
  ];

  const managementFields2 = [
    {
      title: t("managementFields2_1"),
    },
    {
      title: t("managementFields2_2"),
    },
    {
      title: t("managementFields2_3"),
    },
  ];

  return (
    <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5 }}>
      <NavBar />

      <BannerHeader isPrimary={false} headerText={t("services4Title")} bgImage={"signature-blurred.jpg"} bottomSpace={false} />

      <motion.div className="card-container my-24" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }}>
        <motion.div className="card" variants={cardVariantsScaled}>
          <div className="flex flex-wrap max-w-screen-xl m-auto">
            <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
              <div className="flex lg:py-12 justify-center lg:justify-start">
                <motion.div className="card-container z-[10]" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }}>
                  <motion.div className="card mx-12 lg:mx-0" variants={cardVariantsScaled}>
                    <img
                      src="/images/content4.jpg"
                      className="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px] z-[10]"
                      alt="БИДНИЙ ҮЙЛЧИЛГЭЭ"
                    />
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <div className="flex h-full items-center rounded-lg p-6 lg:pl-12">
                <div className="py-6 lg:pl-12">
                  <h2 className="text-2xl font-avertaCyrillic font-light leading-tight tracking-tight uppercase relative text-primary-800">
                    {t("contentManagementDesc1Title")}
                  </h2>
                  <p className="mb-4 leading-relaxed text-primary-900 ">{t("contentManagementDesc1")}</p>
                  <p className="mb-4 leading-relaxed text-primary-900 ">{t("contentManagementDesc2")}</p>

                  <div className="h-24"></div>

                  <h2 className="text-2xl font-avertaCyrillic font-light leading-tight tracking-tight uppercase relative text-primary-800">
                    {t("contentManagementDesc2Title")}
                  </h2>
                  <p className="mb-4 leading-relaxed text-primary-900 ">{t("contentManagementDesc3")}</p>
                  <p className="mb-4 leading-relaxed text-primary-900 ">{t("contentManagementDesc4")}</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <div className="w-full h-full flex flex-col justify-center items-center py-32 mb-32 relative">
        <StaggeredBlocks titleField={t("managementFieldsTitle")} data={managementFields} taller={true} />
        <div className="h-24"></div>
        <StaggeredBlocks titleField={t("managementFieldsTitle2")} data={managementFields2} taller={true} />
        <div
          className="w-full h-full z-10 absolute top-0 opacity-50 bg-cover"
          style={{ backgroundImage: "url(/images/ub2.jpg)", backgroundAttachment: "fixed", filter: "grayscale(100%)" }}
        ></div>
        <div className="bg-master-100 absolute top-0 left-0 w-full h-full opacity-55 z-20"></div>
      </div>

      <JoinUsSection />

      <FollowUsSection />

      <Footer showContactButton={true} />
    </motion.div>
  );
};

export default ContentManagement;
