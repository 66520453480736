import React from "react";
import { useTranslation } from "react-i18next";

import CustomCard from "./elements/CustomCard";

const OurMission = () => {
  const { t } = useTranslation();

  return (
    <div className="container max-w-screen-xl my-24 mx-auto md:px-6 text-center">
      <div className="grid lg:grid-cols-3 lg:gap-x-12 pb-0 pt-10">
        <CustomCard
          imageSrc="/images/justice-scale-white.png"
          altText={t("customCardTitle3")}
          title={t("customCardTitle3")}
          description={t("customCardDesc3")}
          titleColorClass={"text-master-200"}
          textColorClass={"text-white"}
        />

        <CustomCard
          imageSrc="/images/science-white.png"
          altText={t("customCardTitle4")}
          title={t("customCardTitle4")}
          description={t("customCardDesc4")}
          titleColorClass={"text-master-200"}
          textColorClass={"text-white"}
        />

        <CustomCard
          imageSrc="/images/people-white.png"
          altText={t("customCardTitle5")}
          title={t("customCardTitle5")}
          description={t("customCardDesc5")}
          titleColorClass={"text-master-200"}
          textColorClass={"text-white"}
        />
      </div>
    </div>
  );
};

export default OurMission;
