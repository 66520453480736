import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const cardVariants = {
  offscreen: {
    y: -20,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 2,
      delay: 0.2,
    },
  },
};

const CustomHeader = ({ isPrimary, headerText }) => {
  const titleColorBlack = isPrimary ? "text-primary-800" : "text-master-200";
  const borderColorClass = isPrimary ? "border-primary-900" : "border-master-200";
  const [scaleValue, setScaleValue] = useState(1);

  // ========================================================================  //
  // 🟩 Effects

  useEffect(() => {
    const handleScroll = () => {
      var a = 1 + window.scrollY * 0.0001;
      setScaleValue(a);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scaleValue]);

  return (
    <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
      <motion.div className="card" variants={cardVariants}>
        <h1
          className={`text-master-200 uppercase flex flex-col items-center justify-center text-3xl lg:text-5xl my-4 font-geologica text-center lg:text-left relative ${titleColorBlack}`}
        >
          {headerText} <span className={`block h-6 border-b-[1px] w-1/4 mt-[-10px] mx-auto ${borderColorClass}`}></span>
        </h1>
      </motion.div>
    </motion.div>
  );
};

export default CustomHeader;
