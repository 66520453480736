import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ServiceBlocks = () => {
  const { t } = useTranslation();

  const sectionsData = [
    {
      title: t("services1Title"),
      url: "consulting",
    },
    {
      title: t("services2Title"),
      url: "advocacy",
    },
    {
      title: t("services3Title"),
      url: "research",
    },
    {
      title: t("services4Title"),
      url: "management",
    },
  ];

  // Start: Stagger
  const [isVisible, setIsVisible] = useState(false);
  const { ref: wrapperRef, inView: wrapperInView } = useInView({
    triggerOnce: true,
  });

  const animationRefs = useRef([]);

  useEffect(() => {
    if (wrapperInView) {
      setIsVisible(true);
    }
  }, [wrapperInView]);
  // End: Stagger

  return (
    <div className="w-full bg-white h-full flex flex-col justify-center items-center relative" style={{ height: "auto" }}>
      <div
        ref={wrapperRef}
        className="flex flex-col lg:flex-row justify-center items-center mt-0 lg:mt-[-85px]"
        style={{ maxWidth: "1120px", width: "100%" }}
      >
        {sectionsData.map((section, index) => (
          <motion.div
            ref={(element) => (animationRefs.current[index] = element)}
            initial={{ opacity: 0, y: -30 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.9, delay: index * 0.3 }} // Add stagger delay
            style={{ originY: 0.5 }}
            className="w-full"
            key={index}
          >
            <div className="flex flex-col">
              <Link to={`/services/${section.url}`} className="containerServices" data-twe-ripple-init>
                <section className="text-center m-1">
                  <div
                    className={`flex flex-col justify-center items-center font-avertaCyrillic uppercase h-[300px] mx-auto lg:max-w-[280px] w-full ${
                      index % 2 === 0 ? "bg-master-100" : "bg-master-300"
                    }`}
                  >
                    <div className="containerServicesTitle">
                      <h5 className="text-2xl text-white">{section.title}</h5>
                    </div>

                    <div className="sci">
                      <div className="inline-block border-4 border-white px-6 py-3 text-md font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:text-white focus:border-white focus:text-white focus:outline-none focus:ring-0 motion-reduce:transition-none font-avertaCyrillic">
                        {t("moreInfo")}
                      </div>
                    </div>
                  </div>
                </section>
              </Link>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ServiceBlocks;
