import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";

const FollowUsSection = () => {
  const { t } = useTranslation();

  return (
    <div
      className="py-24 bg-cover bg-center relative flex items-center justify-center overflow-hidden"
      style={{ backgroundImage: "url(/images/skyscraper.jpg)" }}
    >
      <div className="bg-master-100 absolute top-0 left-0 w-full h-full opacity-90"></div>

      <div className="polyStatic1"></div>
      <div className="polyStatic2"></div>
      <div className="polyStatic3"></div>
      <div className="polyStatic4"></div>

      <div className="relative z-10 flex flex-row items-center justify-center">
        <span className="text-white text-xl font-geologica font-bold uppercase mr-4">{t("followUs")}</span>

        <motion.div className="block m-auto" whileHover={{ scale: 1.1 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>
          <a
            href="https://www.facebook.com/profile.php?id=100069452176803"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-300 hover:text-white hover:underline cursor-pointer flex mx-auto"
            style={{
              width: "36px",
              height: "36px",
              backgroundImage: `url('data:image/svg+xml,<%3Fxml version="1.0"%3F><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="36px" height="36px"><path fill="rgb(239, 239, 229)" d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"/></svg>')`,
            }}
          >
            <span className="sr-only">BLF - Facebook</span>
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default FollowUsSection;
