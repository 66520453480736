import { motion } from "framer-motion";
import React from "react";

const cardVariants = {
  offscreen: {
    y: -20,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 2,
      delay: 0.2,
    },
  },
};

const BannerHeader = ({ headerText, bgImage, bottomSpace, titleBelow }) => {
  return (
    <div
      className={`relative overflow-hidden bg-cover bg-no-repeat bg-center h-[500px] flex justify-center items-center ${
        bottomSpace ? "mb-32" : "mb-0"
      }`}
      style={{
        backgroundImage: `url(/images/${bgImage})`,
      }}
    >
      <motion.div className="relative z-10 w-full" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div className="card flex flex-row" variants={cardVariants}>
          <div className={`w-[55%] mx-10 flex justify-center items-center ${titleBelow ? "mt-64" : "mt-24"}`}>
            <h2 className="text-4xl md:text-5xl px-4 py-6 font-geologica font-light leading-tight tracking-tight uppercase text-right relative text-white border-r-[6px] border-master-200">
              {headerText}
            </h2>
          </div>
        </motion.div>
      </motion.div>

      <div className="bg-master-100 absolute top-0 left-0 w-full h-full opacity-35"></div>

      <div className="polyStaticInnerBottom2b bg-master-100 opacity-30"></div>
      <div className="polyStaticInnerBottom2c bg-master-200 opacity-40"></div>
      <div className="polyStaticInnerBottom1a bg-master-300 opacity-60"></div>
      <div className="polyStaticInnerBottom1b bg-master-100 opacity-50"></div>
      <div className="polyStaticInnerBottom2a bg-master-100 opacity-50"></div>
    </div>
  );
};

export default BannerHeader;
