import { motion } from "framer-motion";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { useTranslation } from "react-i18next";

const OurTestimonials = () => {
  const { t } = useTranslation();

  const cardVariants = {
    offscreen: {
      y: -10,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.6,
        delay: 0,
      },
    },
  };

  const testimonials = [
    {
      name: t("testimonials1Name"),
      role: t("testimonials1Title"),
      text: t("testimonials1Desc"),
      img: "./images/Naranbaatar-320x320.jpg",
    },
    {
      name: t("testimonials2Name"),
      role: t("testimonials2Title"),
      text: t("testimonials2Desc"),
      img: "./images/Battulga-320x320.jpg",
    },
    {
      name: t("testimonials3Name"),
      role: t("testimonials3Title"),
      text: t("testimonials3Desc"),
      img: "./images/Otgonbat-320x320.jpg",
    },
  ];

  function Item(props) {
    return (
      <div
        id="carouselExampleCaptions"
        className="relative flex flex-col text-center overflow-hidden justify-center"
        style={{ minHeight: "480px" }}
        data-te-carousel-init
        data-te-carousel-slide
      >
        <img className="mx-auto mb-6 rounded-full shadow-l w-[150px]" src={props.item.img} alt="avatar" />
        <div className="flex flex-wrap justify-center">
          <div className="w-full shrink-0 grow-0 basis-auto px-6 lg:w-8/12">
            <h5 className="text-2xl text-master-200">{props.item.name}</h5>
            <p className="mb-4 font-medium text-sm text-primary-600">{props.item.role}</p>
            <p className="mb-6 text-white">{props.item.text}</p>
          </div>
        </div>
      </div>
    );
  }

  function NavButtonRight() {
    return (
      <span className="inline-block h-8 w-8">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="text-white">
          <path
            fill="currentColor"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </span>
    );
  }

  function NavButtonLeft() {
    return (
      <span className="inline-block h-8 w-8">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="text-white">
          <path
            fill="currentColor"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </span>
    );
  }

  return (
    <div className="container max-w-screen-xl md:px-6 mb-12 mt-4">
      <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div className="card" variants={cardVariants}>
          <Carousel
            autoPlay={false}
            NavButton={({ onClick, className, style, next, prev }) => {
              // Other logic

              return (
                <div onClick={onClick} className="relative top-1/2 cursor-pointer">
                  {next && <NavButtonRight />}
                  {prev && <NavButtonLeft />}
                </div>
              );
            }}
            indicatorIconButtonProps={{
              style: {
                color: "#343a40", // 3
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "#adb5bd", // 2
              },
            }}
            navButtonsAlwaysVisible={true}
            animation={"slide"}
            interval={8000}
            duration={400}
          >
            {testimonials.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OurTestimonials;
