import { motion } from "framer-motion";
import React from "react";

const CustomCard = ({ imageSrc, altText, title, description, textColorClass, titleColorClass }) => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  return (
    <motion.div className="card-container" initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
      <motion.div className="card" variants={cardVariantsScaled}>
        <div className="mb-16 lg:mb-0">
          <div className="flex justify-center">
            <div className="-mt-8 inline-block rounded-full bg-secondary-400 p-4 text-primary">
              <img src={imageSrc} alt={altText} width="64" height="64" />
            </div>
          </div>
          <div className="p-6">
            {title !== "" && <h3 className={`${titleColorClass} mb-4 text-2xl font-bold font-nunito`}>{title}</h3>}
            <p className={`${textColorClass} text-lg font-nunito`}>{description}</p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default CustomCard;
