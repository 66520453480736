import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import BannerHeader from "../components/elements/BannerHeader";
import CustomHeader from "../components/elements/CustomHeader";
import OurStats from "../components/OurStats";
import JoinUsSection from "../components/JoinUsSection";

const TeamPage = () => {
  const { t } = useTranslation();
  const [refStats, statsInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 896);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 896);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
    hover: {
      opacity: 0.9,
      transition: {
        type: "spring",
        duration: 5,
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = [
    {
      fname: `${t("teamShinegerelFname")}`,
      lname: `${t("teamShinegerelLname")}`,
      role: t("teamShinegerelTitle"),
      imageSrc: "/images/Shinegerel.jpg",
      url: "Shinegerel",
    },
    {
      fname: `${t("teamAzbayarFname")}`,
      lname: `${t("teamAzbayarLname")}`,
      role: t("teamAzbayarTitle"),
      imageSrc: "/images/Azbayar.jpg",
      url: "Azbayar",
    },
    {
      fname: `${t("teamOtgonjargalFname")}`,
      lname: `${t("teamOtgonjargalLname")}`,
      role: t("teamOtgonjargalTitle"),
      imageSrc: "/images/Otgonjargal.jpg",
      url: "Otgonjargal",
    },
    {
      fname: `${t("teamAltantsetsegFname")}`,
      lname: `${t("teamAltantsetsegLname")}`,
      role: t("teamAltantsetsegTitle"),
      imageSrc: "/images/Altantsetseg.jpg",
      url: "Altantsetseg",
    },
    {
      fname: `${t("teamNarantsetsegFname")}`,
      lname: `${t("teamNarantsetsegLname")}`,
      role: t("teamNarantsetsegTitle"),
      imageSrc: "/images/Narantsetseg.jpg",
      url: "Narantsetseg",
    },
    {
      fname: `${t("teamGuljanFname")}`,
      lname: `${t("teamGuljanLname")}`,
      role: t("teamGuljanTitle"),
      imageSrc: "/images/Guljan.jpg",
      url: "Guljan",
    },
    {
      fname: `${t("teamBilguuntugsFname")}`,
      lname: `${t("teamBilguuntugsLname")}`,
      role: t("teamBilguuntugsTitle"),
      imageSrc: "/images/Bilguuntugs.jpg",
      url: "Bilguuntugs",
    },
    {
      fname: `${t("teamBathuuFname")}`,
      lname: `${t("teamBathuuLname")}`,
      role: t("teamBathuuTitle"),
      imageSrc: "/images/Bathuu.jpg",
      url: "Bathuu",
    },
    {
      fname: `${t("teamTsolmonFname")}`,
      lname: `${t("teamTsolmonLname")}`,
      role: t("teamTsolmonTitle"),
      imageSrc: "/images/Tsolmon.jpg",
      url: "Tsolmon",
    },
    {
      fname: `${t("teamHulanFname")}`,
      lname: `${t("teamHulanLname")}`,
      role: t("teamHulanTitle"),
      imageSrc: "/images/Hulan.jpg",
      url: "Hulan",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-white"
    >
      <NavBar />

      <div className="mb-40">
        <BannerHeader
          headerText={t("pageTeam")}
          bgImage={"autumn-columns-blurred.jpg"}
          bottomSpace={false}
        />

        {isMobile ? (
          <div className="grid items-start grid-cols-2">
            {data.map((cardData, index) => (
              <Link
                key={index}
                to={`/${cardData["url"]}`}
                className="mx-4 my-8"
              >
                <motion.img
                  variants={cardVariantsScaled}
                  whileHover="hover"
                  className="cursor-pointer resizable-image"
                  src={cardData.imageSrc}
                  alt={cardData.fname}
                  loading="lazy"
                  width="340"
                />
                <div className="flex flex-col justify-start items-center">
                  <span
                    className="text-md mt-6 text-center text-primary-900 font-avertaCyrillic uppercase"
                    style={{ lineHeight: "normal" }}
                  >
                    {cardData.lname} {cardData.fname}
                  </span>
                  <span className="h-[2px] mt-4 mb-2 bg-primary-800 w-[26px] flex justify-center items-center"></span>
                  <span
                    className="block text-center text-sm text-master-300 font-geologica mt-2"
                    style={{ lineHeight: "normal" }}
                  >
                    {cardData.role}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="max-w-[1800px] flex items-start justify-center flex-wrap mb-32">
            {data.map((cardData, index) => (
              <Link
                key={index}
                to={`/${cardData["url"]}`}
                className="mx-4 my-8"
              >
                <div className="item_holder slow_zoom">
                  <div className="text_holder"></div>
                  <div
                    style={{ backgroundColor: "#00AE8A54" }}
                    className="portfolio_shader"
                  ></div>
                  <div className="image_holder">
                    <span className="image">
                      <motion.img
                        variants={cardVariantsScaled}
                        whileHover="hover"
                        className="cursor-pointer resizable-image"
                        src={cardData.imageSrc}
                        alt={cardData.fname}
                        loading="lazy"
                        width="340"
                      />
                    </span>{" "}
                  </div>
                </div>
                <div className="flex flex-col justify-start items-center">
                  <span
                    className="text-md mt-6 text-center text-primary-900 font-avertaCyrillic uppercase"
                    style={{ lineHeight: "normal" }}
                  >
                    {cardData.lname} {cardData.fname}
                  </span>
                  <span className="h-[2px] mt-4 mb-2 bg-primary-800 w-[26px] flex justify-center items-center"></span>
                  <span
                    className="block text-center text-sm text-master-300 font-geologica mt-2"
                    style={{ lineHeight: "normal" }}
                  >
                    {cardData.role}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>

      <JoinUsSection />

      {/*
        ||
        || 🟩 Stats
        ||
        */}
      <div
        className="gradientMasterSet w-full flex flex-col justify-center items-center"
        ref={refStats}
      >
        <div className="p-12">
          <CustomHeader isPrimary={false} headerText={t("ourStatTitle")} />

          <motion.div
            key="OurStats"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <OurStats isVisible={statsInView} />
          </motion.div>
        </div>
      </div>

      <Footer showContactButton={true} />
    </motion.div>
  );
};

export default TeamPage;
