import React, { useState, useRef, useEffect } from "react";
import { ScrollPage } from "react-scroll-motion";
import { motion, useScroll, useSpring, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";

import { useTranslation } from "react-i18next";
import NavBar from "../components/NavBar";

import CustomHeader from "../components/elements/CustomHeader";

import "../components/i18next";
import "../App.css";
import Footer from "../components/Footer";
// Nope... Unless it isn't supported...
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import OurTestimonials from "../components/OurTestimonials";
import ServiceBlocks from "../components/ServiceBlocks";

function Home() {
  const { t } = useTranslation();
  const [refWelcome, inViewWelcome] = useInView({ triggerOnce: false, threshold: 0.2 });
  const [inView] = useInView({ triggerOnce: false, threshold: 0.2 });

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const videoRefWelcome = useRef(null);

  useEffect(() => {
    if (!CSS.supports("animation-timeline: scroll()") && matchMedia("(prefers-reduced-motion: no-preference)")) {
      gsap.registerPlugin(ScrollTrigger);

      const scrub = 0.5;
      const trigger = "main";

      gsap.set("p > span", {
        "--progress": 0,
        backgroundPositionX: "calc(-200vmax + (var(--progress) * 200vmax)), calc(-200vmax + (var(--progress) * 200vmax)), 0",
        color: "transparent",
      });

      gsap.to("p > span", {
        "--progress": 1,
        scrollTrigger: {
          trigger,
          scrub,
          start: "top top",
          end: "top top-=75%",
        },
      });

      gsap.to("p > span", {
        color: "white",
        scrollTrigger: {
          trigger,
          scrub,
          start: "top top-=75%",
          end: "bottom bottom",
        },
      });
    }
  }, []);

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1.2, ease: "easeOut" },
    });
  }, [controls]);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;
      setIsScrolled(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const controlsScrollButton = useAnimation();
  useEffect(() => {
    controlsScrollButton.start({ opacity: isScrolled ? 0 : 1, y: isScrolled ? 50 : 0 });
  }, [isScrolled, controlsScrollButton]);

  //
  // Start playing video when its visible on screen
  //
  useEffect(() => {
    if (inViewWelcome) {
      // Play the video when it becomes visible
      videoRefWelcome.current.play();
    } else {
      // Pause the video when it goes out of view
      videoRefWelcome.current.pause();
    }
  }, [inView, inViewWelcome]);

  const [showAfter1sec, setAhowAfter1sec] = useState(false);

  useEffect(() => {
    const timeoutId1 = setTimeout(() => {
      setAhowAfter1sec(true);
    }, 1000);

    return () => clearTimeout(timeoutId1);
  }, []);

  // video sound toggle
  const [isMuted, setIsMuted] = useState(true); // State to track sound muting

  const toggleSound = () => {
    setIsMuted((prevMuted) => !prevMuted); // Toggle sound muted state
    if (videoRefWelcome.current) {
      videoRefWelcome.current.muted = !isMuted; // Set video muted property accordingly
    }
  };

  return (
    <div className="App scroll-smooth">
      <NavBar />

      {/*
        ||
        || 🟩 Big Picture
        ||
        */}
      <ScrollPage
        className="gradientMasterSet relative w-full h-full flex flex-col justify-center items-center min-h-[495px]"
        style={{ height: "auto" }}
      >
        <div className="p-5 md:p-12">
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden" }}>
            <div className="absolute w-full h-full bg-master-300 opacity-45"></div>
            <video
              ref={videoRefWelcome}
              autoPlay
              loop
              muted={isMuted} // Set initial muted state
              playsInline
              style={{
                width: "100%",
                height: "495px",
                objectFit: "cover",
              }}
            >
              <source src="/images/welcome.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <motion.div initial={{ opacity: 0, y: -20 }} animate={controls} exit="never" className="z-20 relative" ref={refWelcome}>
            <motion.button
              style={{
                opacity: showAfter1sec ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                minHeight: "64px",
              }}
            >
              {showAfter1sec && (
                <div className="fadedText max-w-[1260px]">
                  <h1 className="text-white font-avertaCyrillic opacity-70">
                    <span className="uppercase">{t("fadedTextSlogan1")}</span>
                    <span className="uppercase">{t("fadedTextSlogan2")}</span>
                    <span className="uppercase">{t("fadedTextSlogan3")}</span>
                  </h1>
                </div>
              )}
            </motion.button>
          </motion.div>
        </div>

        <motion.button className="absolute left-10 bottom-10" onClick={toggleSound} animate={controlsScrollButton}>
          {!isMuted ? (
            <svg className="w-[30px] h-[30px] fill-[#f0f0f0]" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"></path>
            </svg>
          ) : (
            <svg className="w-[30px] h-[30px] fill-[#f0f0f0]" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"></path>
            </svg>
          )}
        </motion.button>
      </ScrollPage>

      {/*
        ||
        || 🟩 Services Blocks
        ||
        */}
      <ServiceBlocks />

      {/*
        ||
        || 🟩 Best Lawyers
        ||
        */}
      <ScrollPage className="w-full bg-white h-full flex flex-col justify-center items-center min-h-svh" style={{ height: "auto" }}>
        <div className="max-w-screen-lg p-4 flex flex-col mb-32 mt-6">
          <h1 className="text-master-300 uppercase text-3xl lg:text-5xl my-4 font-geologica text-center lg:text-left">{t("bestLawyers")}</h1>

          <p className="leading-relaxed m-4 px-2 text-lg text-primary-900 text-left">{t("whyUsDesc")}</p>
          <p className="leading-relaxed m-4 px-2 text-lg text-primary-900 text-left">{t("whyUsDesc2")}</p>

          <div className="h-12"> </div>

          <h1 className="text-master-300 uppercase text-3xl lg:text-5xl my-4 font-geologica text-center lg:text-left">{t("homeSection2Title")}</h1>
          <div className="flex flex-col lg:flex-row">
            <div>
              <p className="leading-relaxed m-4 px-2 text-lg text-primary-900 text-left">{t("homeSection1Desc")}</p>
              <p className="leading-relaxed m-4 px-2 text-lg text-primary-900 text-left">{t("homeSection2Desc")}</p>
              <p className="leading-relaxed m-4 px-2 text-lg text-primary-900 text-left">{t("homeSection3Desc")}</p>
            </div>

            <img src="/images/logowall.jpg" alt="Best Lawyers" className="block my-4 m-auto" style={{ maxHeight: "270px", width: "auto" }} />
          </div>
        </div>
      </ScrollPage>

      {/* 🟩 Testimonials */}
      <ScrollPage className="w-full" style={{ height: "auto" }}>
        <div className="gradientMasterSet w-full h-full flex flex-col justify-center items-center bg-cover min-h-svh">
          <div className="container max-w-screen-xl mx-auto px-6 md:px-12 xl:px-32">
            <div className="py-24 md:py-12">
              <CustomHeader isPrimary={false} headerText={t("customerReview")} />

              <OurTestimonials />
            </div>
          </div>
        </div>
      </ScrollPage>

      {/*
        ||
        || 🟩 Footer
        ||
        */}
      <div className="min-w-full ">
        <div className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%]">
          <Parallax className="poly1" translateY={[-130, 130]}></Parallax>
          <Parallax className="poly2" translateY={[-130, 130]}></Parallax>
          <Footer showContactButton={true} />
        </div>
      </div>

      {/* {isBottom && <p>You've reached the bottom!</p>} */}
      <motion.div style={{ scaleX }} className="fixed left-0 right-0 bottom-0 h-2 bg-master-200 z-10"></motion.div>
    </div>
  );
}

export default Home;
